import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType } from '@azure/msal-browser';
import { User } from '@core/models/user.model';
import { amlDeskRoutes } from '@core/route-map';
import { EnvironmentService } from '@core/services/environment.service';
import { initErrorTracker } from '@core/utils/error-tracker-initializer';
import { environment } from '@env/environment';
import { TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { AuthActions, LogoutReason } from '@src/app/modules/auth/store/actions/auth.actions';
import { Observable, of } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { KerberosAuthService } from '@/auth/services/kerberos-auth.service';
import { mapUserFromToken, UserTokenClaims } from './user-utils';

const isOnInactivityPage = (): boolean => {
  const logoutUrl = `${globalThis.location.protocol}//${globalThis.location.host}/${amlDeskRoutes.logout}`;
  const logoutReason = localStorage.getItem('logoutReason');

  return globalThis.location.href === logoutUrl && logoutReason === LogoutReason.Inactivity;
};

/**
 * Performs common tasks for setting up user preferences and initializing services.
 * @param {TranslocoService} translationService - The translation service for localization.
 * @param {Store} store - The data store for managing application state.
 * @param {User} userData - The user data object.
 * @return {void}
 */
function performCommonTasks(translationService: TranslocoService, store: Store, userData: User): void {
  translationService.setActiveLang(userData.preferredLanguage);
  store.dispatch(AuthActions.setUserInfo({ user: userData }));
  initErrorTracker();
}

/**
 * Redirects the user to the login page with no active accounts.
 *
 * @param {MsalService} msalService - The MsalService instance.
 * @param {Router} router - The Router instance.
 * @param {EnvironmentService} environmentService - The EnvironmentService instance
 * @returns {Observable<void>} - Observable that resolves to void.
 */
function loginRedirectWithNoAccounts(
  msalService: MsalService,
  router: Router,
  environmentService: EnvironmentService,
): Observable<void> {
  return msalService.loginRedirect({
    scopes: [],
    state: router.url,
    redirectUri: `${environmentService.baseUrl}`, // 'http://localhost:4200/redirect',
  });
}

/**
 * Redirects the user to the login page if there is an error in acquiring a token.
 *
 * @param {MsalService} msalService - The instance of MsalService used for authentication.
 * @param {Router} router - The instance of Router used for navigation.
 * @param {EventMessage} message - The event message containing the details of the error.
 * @param {EnvironmentService} environmentService - The EnvironmentService instance
 * @returns {void}
 */
function loginRedirectStatus(
  msalService: MsalService,
  router: Router,
  message: EventMessage,
  environmentService: EnvironmentService,
): void {
  if (
    message.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE ||
    message.eventType === EventType.ACQUIRE_TOKEN_FAILURE
  ) {
    loginRedirectWithNoAccounts(msalService, router, environmentService);
  }
}

/**
 * This factory is called at boot startup via APP_INITIALIZER, so that all the necessary tasks run before the application really starts.
 * This is the case of the feature flags configuration loading and the azure login.
 *
 * @param msalService
 * @param store
 * @param translocoService
 * @param broadcastService
 * @param router
 * @param kerberosAuthService
 * @param environmentService
 * @returns an observable that when it's resolved will trigger the application boot up
 */
export const bootUpFactory =
  (
    msalService: MsalService,
    store: Store,
    translocoService: TranslocoService,
    broadcastService: MsalBroadcastService,
    router: Router,
    kerberosAuthService: KerberosAuthService,
    environmentService: EnvironmentService,
  ) =>
  (): Observable<void> =>
    msalService.handleRedirectObservable().pipe(
      concatMap((response) => {
        if (isOnInactivityPage()) {
          return of();
        }

        const accounts = msalService.instance.getAllAccounts();

        if (accounts.length === 0) {
          return loginRedirectWithNoAccounts(msalService, router, environmentService);
        }

        // Intercept if there is an error fetching the token to then trigger a login redirection
        broadcastService.msalSubject$.subscribe((message: EventMessage) => {
          return loginRedirectStatus(msalService, router, message, environmentService);
        });

        const account: AccountInfo = accounts[0];
        const user = mapUserFromToken(account.idTokenClaims as UserTokenClaims);

        msalService.instance.setActiveAccount(account);

        if (response) {
          // IMPORTANT!: to use Azure token we have to use id token, not access token,
          // as the id identifies the user using the app with the access token
          const rawIdToken = response.idToken;
          if (!rawIdToken) {
            console.error('No id token found in Azure response');
            return of();
          }
          return kerberosAuthService.login(rawIdToken).pipe(
            tap(() => {
              performCommonTasks(translocoService, store, user);
            }),
          );
        }
        performCommonTasks(translocoService, store, user);
        return of();
      }),
    );

export const bootUpFactoryWithoutExternalAuth =
  (store: Store, translocoService: TranslocoService, kerberosAuthService: KerberosAuthService) =>
  (): Observable<void> => {
    const localDevelopmentUserId = environment.config.localDevelopmentUser?.uid;

    const localDevelopmentUserEmail = environment.config.localDevelopmentUser?.email;
    if (!localDevelopmentUserId || !localDevelopmentUserEmail) {
      throw new Error('localDevelopmentUser not set in development mode');
    }

    return kerberosAuthService.loginWithoutExternalAuth(localDevelopmentUserId, localDevelopmentUserEmail).pipe(
      tap(() => {
        performCommonTasks(translocoService, store, environment.config.localDevelopmentUser as User);
      }),
    );
  };
