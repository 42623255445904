import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthActions, LogoutReason } from '@/auth/store/actions/auth.actions';

@Component({
  selector: 'app-logout-page',
  templateUrl: './logout-page.component.html',
  standalone: false,
})
export class LogoutPageComponent {
  public constructor(private readonly store: Store) {}

  public onBackToLoginClick(): void {
    this.store.dispatch(AuthActions.logout({ logoutReason: LogoutReason.Manual }));
  }
}
