import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastActions } from '../actions';

@Injectable()
export class ToastEffects {
  public constructor(private readonly actions$: Actions) {}

  private closeToastEffect = (): Observable<Action> => {
    return this.actions$.pipe(
      ofType(ToastActions.closeToast),
      tap(() => {
        localStorage.setItem('timeoutWarning', '');
      }),
    );
  };

  /**
   * Close toast effect is activated when the "closeToast" action is dispatched
   */
  public closeToast$ = createEffect(this.closeToastEffect, { dispatch: false });
}
