import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ContractService } from '@/contract/services/contract.service';
import { ContractActions } from '@/contract/store/actions/contract.actions';

@Injectable()
export class ContractEffects {
  public constructor(
    private readonly actions$: Actions,
    private readonly contractService: ContractService,
  ) {}

  private loadContractEffect = (): Observable<Action> => {
    return this.actions$.pipe(
      ofType(ContractActions.loadContract),
      mergeMap((action) =>
        this.contractService.getCustomerContract(action.kerberosAccountId).pipe(
          map((contract) => {
            localStorage.setItem('contract', JSON.stringify(contract));
            return ContractActions.loadContractSuccess({ contract });
          }),
          catchError(() => of(ContractActions.loadContractFailure())),
        ),
      ),
    );
  };

  public loadContract$ = createEffect(this.loadContractEffect);
}
