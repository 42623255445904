import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { guards } from '@app/app.guards';
import { BrowserUtils } from '@azure/msal-browser';
import { LayoutComponent } from '@core/components/layout/layout.component';
import { FeatureFlags } from '@core/feature-flags';
import { RedirectComponent } from '@core/pages/redirect.component';
import { amlDeskRoutes } from '@core/route-map';
import { RoleDisplayName } from '@kerberos-compliance/lib-adp-shared/grants-user/enums/user-roles.enum';
import { roleGuard } from '@/auth/guard/role.guard';
import { UnauthorizedComponent } from '@/auth/pages/unauthorized/unauthorized.component';
import { ContractActiveGuard } from '@/contract/guards/contract.guard';

const routes: Routes = [
  {
    path: amlDeskRoutes.logout,
    loadChildren: () => import('src/app/modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: amlDeskRoutes.dashboard,
    loadChildren: () => import('src/app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [...guards, roleGuard, ContractActiveGuard],
    data: {
      roles: [RoleDisplayName.RiskAnalysisCustomer],
    },
  },
  {
    path: amlDeskRoutes.documents,
    loadChildren: () => import('src/app/modules/documents/documents.module').then((m) => m.DocumentsModule),
    canActivate: [...guards, roleGuard],
    data: {
      feature: FeatureFlags.FileCenter,
      roles: [RoleDisplayName.RiskAnalysisCustomer],
    },
  },
  {
    path: amlDeskRoutes.riskAnalysis,
    loadChildren: () => import('src/app/modules/risk-analysis/risk-analysis.module').then((m) => m.RiskAnalysisModule),
    canActivate: [...guards, roleGuard, ContractActiveGuard],
    data: {
      feature: FeatureFlags.RiskAnalysis,
      roles: [RoleDisplayName.RiskAnalysisCustomer],
    },
  },
  {
    path: amlDeskRoutes.transparencyRegister,
    loadChildren: () =>
      import('src/app/modules/transparency-register/transparency-register.module').then(
        (m) => m.TransparencyRegisterModule,
      ),
    canActivate: [...guards, roleGuard],
    data: {
      feature: FeatureFlags.TransparencyRegister,
      roles: [RoleDisplayName.RiskAnalysisCustomer],
    },
  },
  {
    path: amlDeskRoutes.kycUserCreation,
    loadChildren: () =>
      import('src/app/modules/kyc-user-creation/kyc-user-creation.module').then((m) => m.KycUserCreationModule),
    canActivate: [...guards, roleGuard],
    data: {
      feature: FeatureFlags.Kyc,
      roles: [RoleDisplayName.RiskAnalysisCustomer],
    },
  },
  {
    path: amlDeskRoutes.kyc,
    loadChildren: () => import('src/app/modules/kyc/kyc.module').then((m) => m.KycModule),
    canActivate: [...guards, roleGuard],
    data: { roles: [RoleDisplayName.WorkItemUser] },
  },
  {
    path: amlDeskRoutes.locationAnalysis,
    loadChildren: () => import('src/app/modules/location-analysis/la.module').then((m) => m.LaModule),
    canActivate: [...guards, roleGuard],
    data: { roles: [RoleDisplayName.LocationAnalysisUser] },
  },
  {
    path: amlDeskRoutes.academy,
    loadChildren: () => import('src/app/modules/academy/academy.module').then((m) => m.AcademyModule),
    canActivate: [...guards, roleGuard],
    data: {
      feature: FeatureFlags.Academy,
      roles: [RoleDisplayName.RiskAnalysisCustomer],
    },
  },
  {
    path: amlDeskRoutes.contact,
    loadChildren: () => import('src/app/modules/contact/contact.module').then((m) => m.ContactModule),
    canActivate: guards,
    data: {
      feature: FeatureFlags.Contact,
    },
  },
  {
    path: amlDeskRoutes.contractExpired,
    loadChildren: () => import('@/contract/contract.module').then((m) => m.ContractModule),
    canActivate: [...guards, roleGuard],
    data: {
      roles: [RoleDisplayName.BasicUser, RoleDisplayName.RiskAnalysisCustomer],
    },
  },
  {
    path: amlDeskRoutes.faq,
    loadChildren: () => import('src/app/modules/faq/faq.module').then((m) => m.FaqModule),
    canActivate: [...guards, roleGuard],
    data: {
      feature: FeatureFlags.Faq,
      roles: [RoleDisplayName.BasicUser, RoleDisplayName.RiskAnalysisCustomer],
    },
  },
  {
    path: amlDeskRoutes.unauthorized,
    component: UnauthorizedComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    component: RedirectComponent,
    canActivate: [roleGuard],
    data: {
      enableRedirect: true,
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: LayoutComponent,
          children: routes,
        },
      ],
      {
        // Don't perform initial navigation in iframes or popups
        initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
      },
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const commonRoutesAmlDesk = {
  Unauthorized: 'unauthorized',
  KycIntroduction: 'kyc/kyc-introduction',
  LocationAnalysis: 'location-analysis/la-introduction',
} as const;
