import { Injectable } from '@angular/core';
import { setScreenSizeAndPerformAction } from '@app/store/actions/screen-size.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { concat, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ScreenSizeActions } from '../actions';

@Injectable()
export class ScreenSizeEffects {
  public constructor(private readonly actions$: Actions) {}

  private screenSizeEffect = (): Observable<Action> => {
    return this.actions$.pipe(
      ofType(setScreenSizeAndPerformAction),
      mergeMap(({ screen, action }) => concat(of(ScreenSizeActions.setScreenSize({ screen })), of(action))),
    );
  };

  public screenSelection$ = createEffect(this.screenSizeEffect);
}
