import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Data, Router } from '@angular/router';
import { commonRoutesAmlDesk } from '@app/app-routing.module';
import { amlDeskRoutes } from '@core/route-map';
import { RoleDisplayName } from '@kerberos-compliance/lib-adp-shared/grants-user/enums/user-roles.enum';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import {
  selectAuthTokensAndOwnGrant,
  selectGrantsLoadingComplete,
} from '@/auth/store/selectors/combined-auth.selectors';

export type RouteDataWithRoles = Data & { roles: RoleDisplayName[] };

// Functional guard, no constructor needed, deprecated canActivate, https://v17.angular.io/api/router/CanActivate#canactivate
/**
 * Guards routes based on user roles.
 *
 * This function checks if the current user has the required roles defined in the
 * route's data. If the user has the necessary role, they are granted access to the
 * route. Otherwise, they are redirected to an unauthorized page.
 *
 * @param {ActivatedRouteSnapshot} route - The route snapshot containing route-specific data.
 * @return {Observable<boolean>} - An observable that emits whether the user can activate the route.
 */
export const roleGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const store = inject(Store);
  const router = inject(Router);

  // Get the roles defined in the route's data
  const requiredRoles: RoleDisplayName[] = (route.data as RouteDataWithRoles).roles;
  const enableRedirect: boolean = Boolean(route.data['enableRedirect']);

  return store.select(selectGrantsLoadingComplete).pipe(
    filter((loadingComplete) => loadingComplete), // Wait until grants are loaded
    take(1),
    switchMap(() =>
      store.select(selectAuthTokensAndOwnGrant(undefined, requiredRoles)).pipe(
        take(1),
        map(({ ownGrant }) => {
          if (!ownGrant) {
            // Redirect to unauthorized page if no grants are found
            void router.navigate([commonRoutesAmlDesk.Unauthorized]);
            return false;
          }

          if (enableRedirect) {
            // Handle redirection based on roles
            switch (ownGrant.roleDisplayName) {
              case RoleDisplayName.WorkItemUser: {
                void router.navigate([commonRoutesAmlDesk.KycIntroduction]);
                break;
              }
              case RoleDisplayName.LocationAnalysisUser: {
                void router.navigate([commonRoutesAmlDesk.LocationAnalysis]);
                break;
              }
              default: {
                void router.navigate([amlDeskRoutes.dashboard]);
                break;
              }
            }
            return false; // Redirection paths do not validate the route
          }

          // In regular mode, validate access based on roles
          if (requiredRoles.includes(ownGrant.roleDisplayName)) {
            return true;
          }

          // Redirect to unauthorized page if role validation fails
          void router.navigate([commonRoutesAmlDesk.Unauthorized]);
          return false;
        }),
      ),
    ),
  );
};
