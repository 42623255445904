import { Component, OnDestroy } from '@angular/core';
import { User } from '@core/models/user.model';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { selectUserProfile } from '@/auth/store/selectors/auth.selectors';
import { ContractService } from '@/contract/services/contract.service';
import { ContractActions } from '@/contract/store/actions/contract.actions';
import { ContractState } from '@/contract/store/reducers/contract.reducer';
import { selectContract } from '@/contract/store/selectors/contract.selectors';

@Component({
  selector: 'app-contact-main-page',
  templateUrl: './contract-expired-page.component.html',
  styleUrls: ['./contract-expired-page.component.scss'],
  standalone: false,
})
export class ContractExpiredPageComponent implements OnDestroy {
  public userEmail$: Observable<User | undefined>;

  public contractInfoRequested$: Observable<ContractState>;

  public imageExpired = '/assets/contract-expired.png';

  private destroy$ = new Subject<void>();

  public constructor(
    private readonly contractService: ContractService,
    private readonly store: Store,
  ) {
    this.userEmail$ = this.store.select(selectUserProfile);
    this.contractInfoRequested$ = this.store.select(selectContract);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  /**
   * Sends case information to Salesforce.
   *
   * @param {string} email - The email of the user.
   * @param {string} accountName - The name of the account to associate the case with.
   * @return {void}
   */
  public sendCaseToSalesforce(email: string, accountName: string): void {
    if (email) {
      this.contractService
        .requestContractInformation(email, accountName)
        .pipe(
          switchMap((result) => {
            return of(result);
          }),
        )
        .subscribe((result) => {
          this.store.dispatch(ContractActions.setContractInfoRequested({ contractInfoRequested: result }));
        });
    }
  }
}
