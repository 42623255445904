import { FeaturesAmlDeskWithGrants } from '@core/types/grant.types';
import { RoleDisplayName } from '@kerberos-compliance/lib-adp-shared/grants-user/enums/user-roles.enum';
import { UserGrant } from '@kerberos-compliance/lib-adp-shared/grants-user/types/user-grant.type';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AuthState } from '@/auth/store/reducers/auth.reducers';
import { selectOrganizationId, selectOwnGrants } from '@/auth/store/selectors/auth.selectors';
import { AuthStateTokenAndOwnGrant, FeatureRoleMap } from '@/auth/types/auth.types';
import { selectAuthTokens } from './kerberos-auth.selectors';

/**
 * Determines if a user grant has the specified organization ID and a relevant role.
 *
 * @param {UserGrant} grant - The user grant object containing organization and role details.
 * @param {string | undefined} selectedOrganizationId - The organization ID to match against the user's grant.
 * @param {RoleDisplayName[]} relevantRoles - A list of role display names that are considered relevant.
 * @returns {boolean} Returns true if the user's grant matches the specified organization ID and has a role included in the relevant roles, otherwise returns false.
 */
const hasOrgAndRole = (
  grant: UserGrant,
  selectedOrganizationId: string | undefined,
  relevantRoles: RoleDisplayName[],
): boolean => {
  return grant.organizationId === selectedOrganizationId && relevantRoles.includes(grant.roleDisplayName);
};

const hasRole = (grant: UserGrant, relevantRoles: RoleDisplayName[]): boolean => {
  return relevantRoles.includes(grant.roleDisplayName);
};

/**
 * Selects authentication tokens and user grants for a specified feature and required roles.
 *
 * @param {FeaturesAmlDeskWithGrants} [feature] - The feature for which to retrieve relevant grants.
 * The feature maps to specific roles that are considered relevant.
 *
 * @param {RoleDisplayName[]} [requiredRoles] - The list of required role display names.
 * If provided, the function filters grants to include only those whose roles match the provided list.
 *
 * @returns {MemoizedSelector<object, AuthStateTokenAndOwnGrant>} - A selector that retrieves
 * the authentication state tokens and the user's own grant based on the provided feature and required roles.
 */
export const selectAuthTokensAndOwnGrant = (
  feature?: FeaturesAmlDeskWithGrants,
  requiredRoles?: RoleDisplayName[],
): MemoizedSelector<object, AuthStateTokenAndOwnGrant> =>
  createSelector(
    selectAuthTokens,
    selectOwnGrants,
    selectOrganizationId,
    (authStateTokens, ownGrants, selectedOrganizationId): AuthStateTokenAndOwnGrant => {
      let ownGrant: UserGrant | undefined;
      if (Array.isArray(ownGrants) && ownGrants.length > 0) {
        const relevantRoles = requiredRoles ?? (feature ? FeatureRoleMap[feature] : Object.values(RoleDisplayName));
        const relevantRolesWithoutBasicUser = relevantRoles.filter(
          (roleDisplayName) => roleDisplayName !== RoleDisplayName.BasicUser,
        );

        // Find grants that match organization and relevant roles excluding "BasicUser"
        ownGrant = ownGrants.find((grant) =>
          hasOrgAndRole(grant, selectedOrganizationId, relevantRolesWithoutBasicUser),
        );

        // First fallback: no organizationId + relevant roles excluding "BasicUser"
        if (!ownGrant) {
          ownGrant = ownGrants.find((grant) => hasRole(grant, relevantRolesWithoutBasicUser));
        }

        // Final fallback: no organizationId + any relevant role
        if (!ownGrant) {
          ownGrant = ownGrants.find((grant) => hasRole(grant, relevantRoles));
        }
      }

      return {
        authStateTokens,
        ownGrant,
      };
    },
  );

export const selectAuthState = (state: { auth: AuthState }): AuthState => state.auth;

export const selectGrantsLoadingComplete = createSelector(
  selectAuthState,
  (authState: AuthState): boolean => authState.grantLoadingComplete,
);
