import { RoleDisplayName } from '@kerberos-compliance/lib-adp-shared/grants-user/enums/user-roles.enum';
import { UserGrant } from '@kerberos-compliance/lib-adp-shared/grants-user/types/user-grant.type';
import { AuthStateTokens } from '@/auth/store/reducers/auth-kerberos.reducers';

export type AuthStateTokenAndOwnGrant = { authStateTokens: AuthStateTokens; ownGrant: UserGrant | undefined };

export enum FeaturesAmlDeskWithGrants {
  KnowYourCustomer = 'KnowYourCustomer',
  RiskAnalysis = 'RiskAnalysis',
  LocationAnalysis = 'LocationAnalysis',
}

export const FeatureRoleMap: Record<FeaturesAmlDeskWithGrants, RoleDisplayName[]> = {
  [FeaturesAmlDeskWithGrants.KnowYourCustomer]: [RoleDisplayName.WorkItemUser],
  [FeaturesAmlDeskWithGrants.RiskAnalysis]: [RoleDisplayName.RiskAnalysisCustomer],
  [FeaturesAmlDeskWithGrants.LocationAnalysis]: [RoleDisplayName.LocationAnalysisUser],
};
