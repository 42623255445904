import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '@core/components/layout/footer/footer.component';
import { NavbarContainerComponent } from '@core/components/layout/navbar-container/navbar-container.component';
import { ToolbarComponent } from '@core/components/layout/toolbar/toolbar.component';
import { Store } from '@ngrx/store';
import { RiskAnalysisProcessSelectors } from '@src/app/modules/risk-analysis/store/selectors/risk-analysis-process.selectors';
import { selectIsOpened } from '@src/app/modules/shared/store/selectors/nav.selectors';
import { selectIsLg } from '@src/app/store/selectors/screen-size.selectors';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  imports: [CommonModule, RouterOutlet, NavbarContainerComponent, ToolbarComponent, FooterComponent, AsyncPipe],
  templateUrl: './layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  private sideBarOpen$ = this.store.select(selectIsOpened);

  private isTaskNavigationBarOpen$ = this.store.select(RiskAnalysisProcessSelectors.taskNavigationOpenStatusSelector);

  private isDesktopView$ = this.store.select(selectIsLg);

  /**
   * We need to add extra margin on the left when the side navigation bar is open But only in the desktop mode
   * Except on the Risk Analysis page where we have extra task-navigation bar open
   */
  public marginAlignmentWhenOpenDesktop$ = combineLatest([
    this.sideBarOpen$,
    this.isDesktopView$,
    this.isTaskNavigationBarOpen$,
  ]).pipe(
    map(([sideBarOpen, isDesktopView, isTaskNavigationBarOpen]) => {
      return (sideBarOpen && isDesktopView && !isTaskNavigationBarOpen) || (isTaskNavigationBarOpen && !isDesktopView);
    }),
  );

  /**
   * Add extra margin on the left just for task-navigation-bar
   */
  public extraMarginWhenTaskNavigationBarIsOpen$ = combineLatest([
    this.sideBarOpen$,
    this.isTaskNavigationBarOpen$,
    this.isDesktopView$,
  ]).pipe(
    map(([sideBarOpen, isDesktopView, isTaskNavigationBarOpen]) => {
      return sideBarOpen && isTaskNavigationBarOpen && isDesktopView;
    }),
  );

  /**
   * Add extra padding on the footer in Desktop and mobile view (provided the side nav is closed in mobile view)
   */
  public extraPadding$ = combineLatest([this.sideBarOpen$, this.isDesktopView$]).pipe(
    map(([sideBarOpen, isDesktopView]) => {
      return isDesktopView || (!sideBarOpen && !isDesktopView);
    }),
  );

  public constructor(private readonly store: Store) {}
}
