import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppContract } from '@core/models/contract.model';
import { EnvironmentService } from '@core/services/environment.service';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

// TODO: this should be move to the new Contracts related to organizations
@Injectable({
  providedIn: 'root',
})
export class ContractService extends EntityCollectionServiceBase<AppContract> {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly environmentService: EnvironmentService,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    super('Contract', serviceElementsFactory);
  }

  public getCustomerContract(kerberosAccountId: string): Observable<AppContract> {
    return this.httpClient
      .get<AppContract>(`${this.environmentService.baseAdpHost}/contract/${kerberosAccountId}`, {
        withCredentials: true,
      })
      .pipe(first());
  }

  public requestContractInformation(email: string, accountName: string): Observable<boolean> {
    return this.httpClient
      .post<boolean>(
        `${this.environmentService.baseAdpHost}/contract/contract-information`,
        { email, accountName },
        { withCredentials: true },
      )
      .pipe(first());
  }
}
