// eslint-disable max-lines
import { UUID } from 'node:crypto';
import { Injectable } from '@angular/core';
import { KycWorkItemTypesActions } from '@app/store/actions/kyc-work-item-types.actions';
import { NotificationType } from '@core/components/notification/models/notification.enum';
import { NotificationService } from '@core/components/notification/services/notification.service';
import { User } from '@core/models/user.model';
import { RoleDisplayName } from '@kerberos-compliance/lib-adp-shared/grants-user/enums/user-roles.enum';
import { UserGrant } from '@kerberos-compliance/lib-adp-shared/grants-user/types/user-grant.type';
import { KycItemTypeCacheService } from '@kyc/kyc-item-type.cache.service';
import { WorkItemService } from '@kyc/services/work-item.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concat, Observable, of, switchMap } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { selectLatestGrantByOrganizationId, selectUserProfile } from '@/auth/store/selectors/auth.selectors';
import { ContractActions } from '@/contract/store/actions/contract.actions';
import { AuthService } from '../../services/auth.service';
import { InactivityService } from '../../services/inactivity.service';
import { AuthActions } from '../actions/auth.actions';

@Injectable()
export class AuthEffects {
  public constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly inactivityService: InactivityService,
    private readonly store: Store,
    private readonly kycItemTypeCacheService: KycItemTypeCacheService,
    private readonly notificationService: NotificationService,
    private readonly workItemService: WorkItemService,
  ) {}

  private logoutUserEffect = (): Observable<Action> => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      tap((action) => {
        // Remove (unauthenticated) user info from local storage.
        localStorage.removeItem('user');
        // TODO: if clearing the full local storage, I see again the onboarding tutorial, information not stored in backend, but on localStorage
        // localStorage.clear();

        // Set the logoutReason, so it can be verified on the logout guard.
        localStorage.setItem('logoutReason', action.logoutReason);
        this.inactivityService.stopTracking();

        // TODO: Handle possible msal errors while logging out the user
        this.authService.logout();
      }),
    );
  };

  /**
   * Logout effect is activated when the logout action is dispatched.
   * Removes user info and redirects the unauthenticated user to the login or logout component according to the logoutReason.
   */
  public logout$ = createEffect(this.logoutUserEffect, { dispatch: false });

  private logoutReasonEffect = (): Observable<Action> => {
    return this.actions$.pipe(
      ofType(AuthActions.resetLogoutReason),
      tap(() => {
        localStorage.removeItem('logoutReason');
      }),
    );
  };

  public resetLogoutReason$ = createEffect(this.logoutReasonEffect, { dispatch: false });

  private setUserInformationEffect = (): Observable<void> => {
    return this.actions$.pipe(
      ofType(AuthActions.setUserInfo),
      map((action) => {
        try {
          localStorage.setItem('user', JSON.stringify(action.user));
          // Only remove the 'logoutReason' key from localStorage if it exists
          if (localStorage.getItem('logoutReason')) {
            localStorage.removeItem('logoutReason');
          }
          this.inactivityService.startTracking();
        } catch (error: unknown) {
          const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
          throw new Error('An error occurred in setUserInformationEffect: ' + errorMessage);
        }
      }),
    );
  };

  /**
   * This effect is activated when a user logs in successfully, and we were able to fetch his claims.
   * Set user info effect is activated when the "setUserInfo" action is dispatched.
   * Saves user information in local storage and removes logout information if there is some.
   */
  public setUserInfo$ = createEffect(this.setUserInformationEffect, { dispatch: false });

  private getGrantEffect = (): Observable<Action> => {
    return this.actions$.pipe(
      ofType(AuthActions.getGrant),
      mergeMap((action) => this.handleGrantRequest(action.accessTokenKerberos)),
    );
  };

  public getGrant$ = createEffect(this.getGrantEffect);

  private getGrantErrorEffect = (): Observable<Action> => {
    return this.actions$.pipe(
      ofType(AuthActions.getGrantFailure),
      tap((action) => console.log('getGrantFailure:', action)),
    );
  };

  public getGrantFailure$ = createEffect(this.getGrantErrorEffect);

  private setSessionGrantEffect = (): Observable<Action> => {
    return this.actions$.pipe(
      ofType(AuthActions.setSessionGrant),
      tap(({ organizationId }) => {
        try {
          this.kycItemTypeCacheService.clearWorkItemTypes();
          localStorage.setItem('organizationId', organizationId);
          this.notificationService.notify(NotificationType.Success, 'navigation.organizationWasSelectedSuccess');
        } catch (error) {
          this.notificationService.notify(NotificationType.Error, 'navigation.organizationSelectionError');
          throw error;
        }
      }),
      switchMap(() =>
        this.store.select(selectLatestGrantByOrganizationId).pipe(
          take(1),
          switchMap((ownGrant) => {
            return this.createWorkItemTypesRequest(ownGrant?.roleDisplayName === RoleDisplayName.WorkItemUser);
          }),
        ),
      ),
    );
  };

  public setSessionGrant$ = createEffect(this.setSessionGrantEffect);

  /**
   * Creates an observable for work item types request based on the given user grant status.
   *
   * @param {boolean} hasWorkItemUserGrant - Indicates whether the user has the grant to access work item types.
   * @return {Observable<Action>} An observable that emits the appropriate work item types actions.
   */
  private createWorkItemTypesRequest(hasWorkItemUserGrant: boolean): Observable<Action> {
    // eslint-disable-next-line sonarjs/no-selector-parameter
    return hasWorkItemUserGrant
      ? this.workItemService.getWorkItemTypes().pipe(
          map(() => KycWorkItemTypesActions.loadWorkItemTypes()),
          catchError(() => of(KycWorkItemTypesActions.loadWorkItemTypesFailure())),
        )
      : of();
  }

  /**
   * Creates a contract request action based on the provided kerberosAccountId.
   *
   * @param kerberosAccountId - The ID of the Kerberos account. If undefined, no action is dispatched.
   * @return An Observable of an Action which dispatches the load contract action if the kerberosAccountId is provided.
   */
  private createContractRequest(kerberosAccountId: string | undefined): Observable<Action> {
    return kerberosAccountId ? of(ContractActions.loadContract({ kerberosAccountId })) : of();
  }

  /**
   * Handles the grant request process by fetching grants using the provided access token,
   * saving the grants to storage, processing them, and handling any errors.
   *
   * @param {string} accessToken - The access token used to fetch grants.
   * @return {Observable<Action>} An observable emitting actions based on the grant processing outcome.
   */
  public handleGrantRequest = (accessToken: string): Observable<Action> => {
    return this.authService.getGrant(accessToken).pipe(
      tap((grants) => this.saveGrantsToStorage(grants)),
      mergeMap((grants) => this.processGrants(grants)),
      catchError(this.handleGrantError),
    );
  };

  /**
   * Saves the provided grants to local storage.
   *
   * @param {UserGrant[]} grants - An array of grant objects to be saved.
   * @return {void}
   */
  private saveGrantsToStorage(grants: UserGrant[]): void {
    localStorage.setItem('ownGrants', JSON.stringify(grants));
  }

  /**
   * Processes the provided grants and determines the flow based on user roles and organization ID.
   *
   * @param {UserGrant[]} grants - The list of grants assigned to the current user.
   * @return {Observable<Action>} - An observable that emits the appropriate actions depending on the user profile and grants.
   */
  private processGrants(grants: UserGrant[]): Observable<Action> {
    const hasWorkItemUserGrant = this.hasWorkItemUserRole(grants);
    const organizationId = this.determineOrganizationId(grants);
    const hasLocationAnalysisGrant = this.hasLocationAnalysisRole(grants);
    const actions = this.createBaseActions(grants, organizationId);

    return this.store.select(selectUserProfile).pipe(
      take(1),
      mergeMap((userProfile) => {
        if (hasWorkItemUserGrant) {
          return this.handleWorkItemUserFlow(actions);
        }
        return hasLocationAnalysisGrant
          ? this.handleLocationAnalysisFlow(actions)
          : this.handleRegularUserFlow(actions, userProfile);
      }),
    );
  }

  /**
   * Checks if any of the provided grants include the WorkItemUser role.
   *
   * @param {UserGrant[]} grants - The array of grants to check against.
   * @return {boolean} - Returns true if any grant includes the WorkItemUser role, otherwise false.
   */
  private hasWorkItemUserRole(grants: UserGrant[]): boolean {
    return grants.some((grant) => grant.roleDisplayName === RoleDisplayName.WorkItemUser);
  }

  /**
   * Determines the organization ID based on the provided grants.
   * If the stored organization ID is not present or not in the list of organization IDs from grants,
   * it updates the stored organization ID with the first available ID from the grants.
   *
   * @param {UserGrant[]} grants - An array of UserGrant objects from which to extract organization IDs.
   * @return {string} The determined organization ID.
   */
  private determineOrganizationId(grants: UserGrant[]): string {
    const organizationIds = grants.map((grant) => grant.organizationId).filter((id): id is UUID => !!id);
    const storedOrgId = localStorage.getItem('organizationId');

    if (!storedOrgId || !organizationIds.includes(storedOrgId as UUID)) {
      const newOrgId = organizationIds[0];
      localStorage.setItem('organizationId', newOrgId);
      return newOrgId;
    }
    return storedOrgId;
  }

  /**
   * Creates a base set of actions for handling grants and session information.
   *
   * @param {UserGrant[]} grants - The array of grant objects to be processed.
   * @param {string} organizationId - The ID of the organization for the session grant.
   */
  private createBaseActions(
    grants: UserGrant[],
    organizationId: string,
  ): {
    grantSuccess: { grants: UserGrant[] } & Action<'[Auth] Get Grant Success'>;
    sessionGrant: { organizationId: string } & Action<'[Auth] Set session Organization grant for user'>;
  } {
    return {
      grantSuccess: AuthActions.getGrantSuccess({ grants }),
      sessionGrant: AuthActions.setSessionGrant({ organizationId }),
    };
  }

  /**
   * Handles the user flow for a work item.
   *
   * @param {ReturnType<typeof createBaseActions>} actions - The base actions necessary for the work item flow.
   * @return {Observable<Action>} - An observable sequence of actions that dictate the work item flow.
   */
  private handleWorkItemUserFlow(actions: ReturnType<typeof this.createBaseActions>): Observable<Action> {
    return concat(of(actions.grantSuccess), of(actions.sessionGrant), of(AuthActions.grantLoadingComplete())).pipe();
  }

  /**
   * Handles the regular user flow by generating a sequence of actions
   * including granting success, session grant, contract request,
   * and completing grant loading.
   *
   * @param {ReturnType<typeof this.createBaseActions>} actions - The set of base actions to be dispatched.
   * @param {User | undefined} userProfile - The user profile which contains information such as the Kerberos account ID.
   * @return {Observable<Action>} An observable sequence of actions to be dispatched.
   */
  private handleRegularUserFlow(
    actions: ReturnType<typeof this.createBaseActions>,
    userProfile: User | undefined,
  ): Observable<Action> {
    return concat(
      of(actions.grantSuccess),
      of(actions.sessionGrant),
      this.createContractRequest(userProfile?.kerberosAccountId),
      of(AuthActions.grantLoadingComplete()),
    );
  }

  /**
   * Handles the grant error by dispatching a failure action and a loading complete action.
   *
   * @param {unknown} error - The error object encountered during the grant process.
   * @returns {Observable<Action>} An observable stream of actions to be dispatched.
   */
  private handleGrantError = (error: unknown): Observable<Action> => {
    return concat(of(AuthActions.getGrantFailure({ error: error as Error })), of(AuthActions.grantLoadingComplete()));
  };

  /**
   * Checks if any of the provided grants include the LocationAnalysis role.
   *
   * @param {UserGrant[]} grants - The array of grants to check against.
   * @return {boolean} - Returns true if any grant includes the LocationAnalysis role, otherwise false.
   */
  private hasLocationAnalysisRole(grants: UserGrant[]): boolean {
    return grants.some((grant) => grant.roleDisplayName === RoleDisplayName.LocationAnalysisUser);
  }

  /**
   * Handles the user flow for Location Analysis.
   *
   * @param {ReturnType<typeof createBaseActions>} actions - The base actions necessary for the flow.
   * @return {Observable<Action>} - An observable sequence of actions that dictate the Location Analysis flow.
   */
  private handleLocationAnalysisFlow(actions: ReturnType<typeof this.createBaseActions>): Observable<Action> {
    return concat(of(actions.grantSuccess), of(AuthActions.grantLoadingComplete())).pipe();
  }
}
