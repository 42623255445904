import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScreenSizeEffects } from '@app/store/effects/screen-size.effects';
import { MsalModule } from '@azure/msal-angular';
import { LayoutComponent } from '@core/components/layout/layout.component';
import { environment } from '@env/environment';
import { EntityDataModule } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { entityMetadata } from '@src/app/core/data/entity.config';
import { AuthModule } from '@/auth/auth.module';
import { ContractModule } from '@/contract/contract.module';
import { SharedModule } from '@/shared/shared.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { getAppModuleProviders } from './provider';
import { ToastEffects } from './store/effects/app.effects';
import { KycWorkItemTypesEffects } from './store/effects/kyc-work-item-types.effects';
import { appKey, appReducer } from './store/reducers/app.reducers';
import { kycWorkItemsTypesReducer, kycWorkItemTypesFeatureKey } from './store/reducers/kyc-work-item-types.reducer';
import { TranslocoRootModule } from './transloco-root.module';

let appModuleImports: Array<Type<unknown> | ModuleWithProviders<object> | unknown[]> = [
  BrowserModule,
  SharedModule,
  BrowserAnimationsModule,
  AuthModule,
  StoreModule.forRoot({ [appKey]: appReducer }),
  StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.productionBuild, connectInZone: true }),
  EffectsModule.forRoot([ToastEffects, ScreenSizeEffects]),
  StoreModule.forFeature(kycWorkItemTypesFeatureKey, kycWorkItemsTypesReducer),
  EffectsModule.forFeature([KycWorkItemTypesEffects]),
  EntityDataModule.forRoot({ entityMetadata }),
  AppRoutingModule,
  TranslocoRootModule,
  LayoutComponent,
  MatSnackBarModule,
  ContractModule,
  ReactiveFormsModule,
];

const msalImports = environment.debugFlags.includes('noExternalAuth') ? [] : [MsalModule];
appModuleImports = [...appModuleImports, msalImports];

@NgModule({
  declarations: [AppComponent],
  imports: appModuleImports,
  providers: getAppModuleProviders(),
  bootstrap: [AppComponent],
})
export class AppModule {}
